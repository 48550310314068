import React from 'react';
import Layout from '../components/Layout';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout bodyClass="page-default-single">
        <div className="container pb-6 pt-6 pt-md-10 pb-md-10">
          <div className="row justify-content-start">
            <div className="col-12 col-md-8">
              <h1 className="title">Puslapis nerastas</h1>
              <div className="content">
              Puslapis, kurį bandėte pasiekti, mūsų svetainėje nerastas. Taip galėjo nutikti, jei pasikeitė jo adresas ar jis buvo pašalintas iš serverio.
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
